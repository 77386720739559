import React from "react";
import toast, { Toaster } from "react-hot-toast";

function ContactUs() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = e.target.elements;
    const response = await fetch("/api/sendgrid", {
      body: JSON.stringify({
        name: name.value,
        email: email.value,
        message: message.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((res) => res.json());
    if (response.success) {
      toast.success(response.message);
      document.getElementById("contactform").reset();
      document.getElementById("close").click();
    } else {
      toast.error(response.message);
      document.getElementById("close").click();
    }
  };

  return (
    <>
      <div
        className="modal fade modal-contact-popup-one"
        id="contactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contactModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <main className="main-body modal-content clearfix">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              id="close"
              aria-label="Close"
              role="button"
            >
              <img src="/images/icon/close.svg" alt="close" />
            </button>
            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “I never dreamed about success. I worked for it.”
                    </blockquote>
                    <span className="bio">—Estée Lauder</span>
                  </div>
                </div>
                <img
                  src="/images/assets/ils_18.svg"
                  alt="ils_18"
                  className="illustration mt-auto"
                />
              </div>
            </div>
            <div className="right-side">
              <h2 className="form-title">Contact us</h2>
              <form onSubmit={(e) => handleSubmit(e)} id="contactform">
                <div className="messages"></div>
                <div className="row controls">
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-15">
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        required="required"
                        data-error="Name is required."
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-35">
                      <label>Email*</label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        required="required"
                        data-error="Valid email is required."
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-25">
                      <textarea
                        placeholder="Your message"
                        name="message"
                        required="required"
                        data-error="Please,leave us a message."
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      role="button"
                      className="theme-btn-seven w-100"
                      name="sendmail"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
      <Toaster position="bottom-center" />
    </>
  );
}
export default ContactUs;
