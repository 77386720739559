import Link from "next/link";
import React from "react";
import { FaTiktok } from "react-icons/fa";
import ContactUs from "../ContactUs/ContactUs";

function ArabicFooter() {
  return (
    <>
      <footer className="theme-footer-three pt-100 mobilearabic">
        <div className="top-footer">
          <div className="container">
            <div className="row ml-0 mx-0 justify-content-between">
              <div className="col-lg-4 col-4 footer-about-widget">
                <Link
                  href="/"
                  className="logo cursor"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src="/images/job/arabic-logo.svg"
                    alt="arabic-logo"
                    width="163"
                    height="40"
                    style={{ width: "160px" }}
                  />
                </Link>
              </div>

              <div className="col-lg-2 col-md-4 footer-list">
                <p className="footer-title">معلومات عنا</p>
                <ul>
                  <li>
                    <Link href="/user-aggrement" passHref>
                      <a>اتفاقية المستخدم</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions" passHref>
                      <a>شروط الاستخدام</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy" passHref>
                      <a>سياسة الخصوصية</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/cookies-policy" passHref>
                      <a>اتفاقية ملفات تعريف الارتباط</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/disclamier" passHref>
                      <a>عدم اعطاء رأي</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://blog.skatch.com/" passHref>
                      <a target="_blanks">المدونات</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 address-list">
                <p
                  className="footer-title"
                  style={{ fontSize: "18px", color: "#000", fontWeight: "600" }}
                >
                  معلومات الاتصال
                </p>
                <p
                  className="font-gordita"
                  style={{ fontWeight: "400", color: "#1C1C1C" }}
                >
                  القاهرة&nbsp; ,مصر
                </p>
                <ul className="info">
                  <li>
                    <a
                      href="mailto:hello@skatch.com"
                      style={{ textDecoration: "none" }}
                    >
                      hello@skatch.com
                    </a>
                  </li>
                  {/* <li><a style={{textDecoration:'none'}} className="mobile-num">+761 412 3224</a></li> */}
                </ul>
                <ul className="social-icon d-flex pt-15">
                  <li>
                    <a
                      href="https://www.facebook.com/skatch.com.official"
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/skatch.com.official/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/skatch"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@skatch.com"
                      target="_blank"
                    >
                      <FaTiktok></FaTiktok>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="bottom-footer-content">
            <div className="row ml-0 mx-0">
              <div className="col-lg-8 ml-auto">
                <div className="d-md-flex align-items-center justify-content-between">
                  <ul className="order-md-last" style={{ width: "100%" }}>
                    <li
                      className="text-center1"
                      style={{
                        width: "100%",
                        textAlign: "right",
                        paddingRight: "5%",
                      }}
                    >
                      سكاتش شركة. {new Date().getFullYear()} @ حقوق النشر
                      {/* @{new Date().getFullYear()} Skatch inc. */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ContactUs />
      <button className="scroll-top" aria-label="top" role="button">
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </button>
    </>
  );
}
export default ArabicFooter;
